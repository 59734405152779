<template>
  <div class="resourceActive">
    <el-card class="box-card">
      <h3>活动数据</h3>
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="活动名称">
          <el-input v-model="dataForm.name" placeholder="请输入活动名称" />
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="activeTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="activeTimeChange"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            round
            plain
            @click="queryTableList()"
            >查询
          </el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset"
            >重置
          </el-button>
          <el-button
            size="small"
            :loading="isExportLoading"
            type="danger"
            :disabled="!dataList.length"
            icon="el-icon-download"
            @click="confirmDownload()"
            >导出
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table
        :data="dataList"
        v-loading="dataListLoading"
        style="width: 100%"
        height="60vh"
        highlight-current-row
        stripe
      >
        <el-table-column
          prop="activityId"
          width="160"
          label="活动编号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          width="160"
          label="活动名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="startTime"
          width="160"
          label="开始时间"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="endTime"
          width="160"
          label="截止时间"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inventory"
          width="160"
          label="商品活动数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="productNum"
          width="160"
          label="商品活动库存数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="transactionUserNum"
          width="160"
          label="成交用户数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="transactionOrderNum"
          width="160"
          label="成交订单量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="salesVolume"
          width="160"
          label="销售额"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="perCustomer"
          width="160"
          label="客单价"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="storeRevenue"
          width="160"
          label="店铺营收"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="goDataShow(scope.row)"
              >数据总览
            </el-button>
            <el-button size="small" @click="goShopData(scope.row)"
              >商品数据
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right; padding: 10px 0"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="dataForm.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="dataForm.pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { export_json_to_excel } from '@/vendor/Export2Excel';

const defaultParams = {
  name: '',
  pageSize: 10,
  pageNo: 1,
  endTime: '', //结束时间
  startTime: '', //开始时间
};
export default {
  name: 'activeData',
  data() {
    return {
      dataForm: Object.assign({}, defaultParams),
      dataListLoading: false,
      dataList: [],
      totalPage: 0,
      isExportLoading: false,
      activeTime: '',
    };
  },
  methods: {
    // 去数据总览
    goDataShow(row) {
      this.$router.push({
        path: 'dataShow',
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    //活动时间时间
    activeTimeChange(e) {
      this.orderTime = e;
      this.dataForm.startTime = e ? e[0] : null;
      this.dataForm.endTime = e ? e[1] : null;
    },
    // 去商品数据
    goShopData(row) {
      this.$router.push({
        path: 'shopData',
        query: {
          row,
        },
      });
    },
    // 获取数据列表
    queryTableList() {
      this.dataListLoading = true;
      this.$api.active.reqActiveData(this.dataForm).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.totalPage = Number(res.data.total);
          this.$message.success('查询成功');
        } else {
          this.dataList = [];
          this.totalPage = 0;
          this.$message.error('查询失败');
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.queryTableList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.queryTableList();
    },
    // 重置
    reset() {
      this.dataForm = Object.assign({}, defaultParams);
      this.queryTableList();
    },
    //确认下载
    confirmDownload() {
      let params = JSON.parse(JSON.stringify(this.dataForm));
      params.pageSize = this.totalPage;
      this.isExportLoading = true;
      //获取下载数据
      this.$api.active.reqActiveData(params).then((res) => {
        if (res.success) {
          let tHeader = [
            '活动编号',
            '活动名称',
            '开始时间',
            '截止时间',
            '活动商品数',
            '商品活动库存数',
            '成交用户数',
            '成交订单量',
            '销售额',
            '客单价',
            '店铺营收',
          ];
          let filterVal = [
            'activityId',
            'name',
            'startTime',
            'endTime',
            'productNum',
            'inventory',
            'transactionUserNum',
            'transactionOrderNum',
            'salesVolume',
            'perCustomer',
            'storeRevenue',
          ];
          const data = this.formatJson(filterVal, res.data.list);
          export_json_to_excel({
            header: tHeader,
            data,
            fileName: '活动数据列表',
          });
          this.isExportLoading = false;
        }
      });
    },
  },
  created() {
    this.queryTableList();
  },
};
</script>

<style></style>
